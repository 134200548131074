import React from "react";

function Informe() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 512 512"
    >
      <path
        fill="#ffbd86"
        d="M255.575 22c-135.31 0-245 109.69-245 245 0 60.342 21.828 115.577 58 158.272L147.666 487c32.559 16 69.181 25 107.909 25 36.275 0 70.705-7.892 101.677-22.04-27.487-14.32-46.31-43.066-46.31-76.144 0-47.319 38.497-85.816 85.816-85.816 34.262 0 63.886 20.189 77.633 49.29 16.742-33.15 26.183-70.615 26.183-110.29.001-135.31-109.689-245-244.999-245z"
      ></path>
      <path
        fill="#22b27f"
        d="M310.942 413.816c0-47.319 38.497-85.816 85.816-85.816 16.84 0 32.549 4.895 45.816 13.308V26c0-5.523-4.477-10-10-10h-97v28c0 2.206-1.794 4-4 4h-152c-2.206 0-4-1.794-4-4V16h-97c-5.523 0-10 4.477-10 10v399.272C90.3 450.914 117.199 472.027 147.666 487h204.329c-24.604-15.105-41.053-42.257-41.053-73.184z"
      ></path>
      <path
        fill="#09a76d"
        d="M121 26c0-5.523 4.477-10 10-10H78.575c-5.523 0-10 4.477-10 10v399.272A246.289 246.289 0 00121 471.731z"
      ></path>
      <path
        fill="#fff"
        d="M310.942 413.816c0-47.319 38.497-85.816 85.816-85.816a85.88 85.88 0 0115.816 1.486V39a3 3 0 00-3-3h-74v8c0 2.206-1.794 4-4 4h-152c-2.206 0-4-1.794-4-4v-8h-74a3 3 0 00-3 3v405a3 3 0 003 3h216.047a85.376 85.376 0 01-6.679-33.184z"
      ></path>
      <path
        fill="#f8f6f6"
        d="M139 444V39a3 3 0 013-3h-40.425a3 3 0 00-3 3v405a3 3 0 003 3H142a3 3 0 01-3-3z"
      ></path>
      <path
        fill="#9c9c9c"
        d="M306.075 48v8.1h-20.693c-5.562 0-10.38 3.61-11.988 8.982-2.337 7.802-9.665 13.251-17.819 13.251s-15.482-5.449-17.819-13.25c-1.608-5.373-6.426-8.983-11.988-8.983h-20.693V48h-15v10.6c0 6.893 5.607 12.5 12.5 12.5h21.376c4.747 13.177 17.499 22.233 31.624 22.233s26.876-9.056 31.624-22.233h21.376c6.893 0 12.5-5.607 12.5-12.5V48z"
      ></path>
      <circle cx="176.701" cy="141.749" r="31.126" fill="#26a6fe"></circle>
      <path
        fill="#0593fc"
        d="M166 141.749c0-13.612 8.741-25.175 20.913-29.403a31.074 31.074 0 00-10.212-1.723c-17.19 0-31.126 13.935-31.126 31.126 0 17.19 13.935 31.126 31.126 31.126 3.578 0 7.012-.612 10.212-1.723C174.741 166.924 166 155.36 166 141.749z"
      ></path>
      <path
        fill="#fff"
        d="M174.596 158.566a7.477 7.477 0 01-5.303-2.196l-12.869-12.868a7.5 7.5 0 010-10.606 7.5 7.5 0 0110.606-.001l7.565 7.565 11.775-11.775a7.501 7.501 0 0110.606 10.607l-17.078 17.078a7.471 7.471 0 01-5.302 2.196z"
      ></path>
      <path
        fill="#9c9c9c"
        d="M365.575 134.249h-131c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h131c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5zM322.575 164.249h-88c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h88c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5z"
      ></path>
      <circle cx="176.701" cy="221.126" r="31.126" fill="#26a6fe"></circle>
      <path
        fill="#0593fc"
        d="M166 221.126c0-13.612 8.741-25.175 20.913-29.403A31.074 31.074 0 00176.701 190c-17.19 0-31.126 13.935-31.126 31.126 0 17.19 13.935 31.126 31.126 31.126 3.578 0 7.012-.612 10.212-1.723C174.741 246.301 166 234.737 166 221.126z"
      ></path>
      <path
        fill="#fff"
        d="M174.596 237.943a7.479 7.479 0 01-5.303-2.196l-12.869-12.868a7.5 7.5 0 010-10.606 7.5 7.5 0 0110.606-.001l7.565 7.565 11.775-11.774a7.5 7.5 0 0110.606.001 7.5 7.5 0 010 10.606l-17.078 17.077a7.473 7.473 0 01-5.302 2.196z"
      ></path>
      <g>
        <path
          fill="#9c9c9c"
          d="M365.575 213.626h-131c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h131c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5zM322.575 243.626h-88c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h88c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5z"
        ></path>
      </g>
      <g>
        <circle cx="176.701" cy="300.503" r="31.126" fill="#26a6fe"></circle>
        <path
          fill="#0593fc"
          d="M166 300.503c0-13.612 8.741-25.175 20.913-29.403a31.074 31.074 0 00-10.212-1.723c-17.19 0-31.126 13.935-31.126 31.126 0 17.19 13.935 31.125 31.126 31.125 3.578 0 7.012-.612 10.212-1.723C174.741 325.678 166 314.114 166 300.503z"
        ></path>
        <path
          fill="#fff"
          d="M174.596 317.32a7.479 7.479 0 01-5.303-2.196l-12.869-12.868a7.5 7.5 0 010-10.606 7.5 7.5 0 0110.606-.001l7.565 7.565 11.775-11.774a7.5 7.5 0 0110.606.001 7.5 7.5 0 010 10.606l-17.078 17.077a7.473 7.473 0 01-5.302 2.196z"
        ></path>
        <g>
          <path
            fill="#9c9c9c"
            d="M350.023 278.003H234.575c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h112.378z"
          ></path>
          <path
            fill="#ffe5c2"
            d="M352.231 271.041l-1.826.101-9.793 47.856c-.329 1.607 1.577 2.707 2.804 1.619l36.548-32.409-.794-1.613z"
          ></path>
          <path
            fill="#fed2a4"
            d="M363.529 277.563l-11.298-6.523-1.826.101-9.793 47.856c-.329 1.607 1.577 2.707 2.804 1.619l13.807-12.243z"
          ></path>
          <path
            fill="#9c9c9c"
            d="M292.575 323.003h-58c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h58c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5z"
          ></path>
        </g>
      </g>
      <g>
        <circle cx="176.701" cy="379.88" r="31.126" fill="#d8ecfe"></circle>
        <path
          fill="#b3dafe"
          d="M166 379.88c0-13.612 8.741-25.175 20.913-29.403a31.074 31.074 0 00-10.212-1.723c-17.19 0-31.126 13.935-31.126 31.126 0 17.19 13.935 31.125 31.126 31.125 3.578 0 7.012-.612 10.212-1.723C174.741 405.055 166 393.491 166 379.88z"
        ></path>
        <path
          fill="#9c9c9c"
          d="M332.193 357.38h-97.618c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h87.065a86.18 86.18 0 0110.553-15zM292.575 402.38h-58c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h58c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5z"
        ></path>
      </g>
      <g>
        <path
          fill="#0182fc"
          d="M331.575 49h-152a5 5 0 01-5-5V5a5 5 0 015-5h152a5 5 0 015 5v39a5 5 0 01-5 5z"
        ></path>
      </g>
      <path
        fill="#0172fb"
        d="M230 29a5 5 0 01-5-5V0h-45.425a5 5 0 00-5 5v39a5 5 0 005 5h152a5 5 0 005-5V29z"
      ></path>
      <g>
        <path
          fill="#9c9c9c"
          d="M497.157 68.159l-14.78-8.533a8.533 8.533 0 00-11.656 3.123l-19.626 33.993.904 1.507 26.603 15.359 2.052.2 19.626-33.993a8.532 8.532 0 00-3.123-11.656z"
        ></path>
        <path
          fill="#5a5a5a"
          d="M359.103 306.707l-14.287-8.249-4.203 20.539c-.329 1.607 1.577 2.707 2.804 1.619z"
        ></path>
        <path
          fill="#fac600"
          d="M398.464 91.786h34.132v201.379h-34.132z"
          transform="rotate(30 415.533 192.453)"
        ></path>
        <path
          fill="#838383"
          d="M477.315 81.542a8.532 8.532 0 0111.656-3.123l9.086 5.246 2.222-3.849a8.533 8.533 0 00-3.123-11.656l-14.78-8.533a8.533 8.533 0 00-11.656 3.123l-19.626 33.993.904 1.507 11.753 6.785z"
        ></path>
        <path
          fill="#e8b800"
          d="M457.689 115.535l16.477 9.512 6.488-11.239-29.559-17.066-100.689 174.4 13.083 7.553z"
        ></path>
      </g>
      <g>
        <circle cx="396.759" cy="413.816" r="86.816" fill="#0182fc"></circle>
        <path
          fill="#0172fb"
          d="M349 413.816c0-41.232 28.745-75.75 67.288-84.61A87.083 87.083 0 00396.759 327c-47.947 0-86.816 38.869-86.816 86.816s38.869 86.816 86.816 86.816c6.715 0 13.252-.763 19.529-2.206-38.543-8.86-67.288-43.377-67.288-84.61z"
        ></path>
        <path
          fill="#fff"
          d="M437.042 398.111h-21.933a2.645 2.645 0 01-2.645-2.645v-21.933a2.645 2.645 0 00-2.645-2.645h-26.121a2.645 2.645 0 00-2.645 2.645v21.933a2.645 2.645 0 01-2.645 2.645h-21.933a2.645 2.645 0 00-2.645 2.645v26.121a2.645 2.645 0 002.645 2.645h21.933a2.645 2.645 0 012.645 2.645V454.1a2.645 2.645 0 002.645 2.645h26.121a2.645 2.645 0 002.645-2.645v-21.933a2.645 2.645 0 012.645-2.645h21.933a2.645 2.645 0 002.645-2.645v-26.121a2.645 2.645 0 00-2.645-2.645z"
        ></path>
        <path
          fill="#f8f6f6"
          d="M400.012 446a2.645 2.645 0 01-2.645-2.645v-21.933a2.645 2.645 0 00-2.645-2.645h-21.933a2.645 2.645 0 01-2.645-2.645V398.11h-13.669a2.645 2.645 0 00-2.645 2.645v26.121a2.645 2.645 0 002.645 2.645h21.933a2.645 2.645 0 012.645 2.645v21.933a2.645 2.645 0 002.645 2.645h26.121a2.645 2.645 0 002.645-2.645V446z"
        ></path>
      </g>
    </svg>
  );
}

export default Informe;
