import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import HeroMain from "../components/home/HeroMain"
import TeleConsulta from "../components/home/TeleConsulta"
import Resultados from "../components/home/Resultados";
import Servicios from "../components/home/Servicios";
import Plataforma from "../components/home/Plataforma"
import Cta from "../components/home/Cta"
import Alianzas from "../components/home/Alianzas2"

const IndexPage = (props) => (
  <Layout>
    <Seo  
    title="Inicio" 
    />
    <HeroMain></HeroMain>
    <TeleConsulta/>
    <Servicios/>
    <Resultados/>
    <Plataforma/>
    <Cta/>
    <Alianzas/>

   
  </Layout>
);

export default IndexPage;

