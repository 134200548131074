import React from "react"
import Pdf from "../icons/Pdf"
import Curita from "../icons/Curita"
import Youtube from "../icons/Youtube"
import Informe from "../icons/Informe"
import Capacitacion from "../icons/Capacitacion"
import { Link } from "gatsby";

const Servicios = () => {
    return (
    <section id = "servicios">
        <div className = "serviciosBlock">
            <h1>Qué hacemos</h1>
            <div className = "row">
                <div className = "column">
                    <div className = "block">
                        <Curita></Curita>
                        <h2>Servicios médicos especializados</h2>
                        <p>Amplia red de especialista y subespecialistas.</p>
                        <Link to = "servicios#servicios-medicos">Explora más</Link>      
                    </div>
                    <div className = "block">
                        <Youtube></Youtube>
                        <h2>TeleEducación</h2>
                        <p>Contamos con un canal de Youtube,donde impusalmos la educación 
                            en línea. </p>
                        <Link to = "servicios#tele-educacion">Explora más</Link>    
 
                   </div>
                </div>
                <div className = "columns">
                    <div className = "block">
                        <Pdf></Pdf>
                        <h2>Productos y manuales</h2>
                        <p>Contamos con un listado de manuales para la implementación de la 
                            Telesalud.</p>
                        <Link to = "servicios#manuales">Explora más</Link>
                    </div>
                    <div className = "block">
                        <Informe></Informe>
                        <h2>Diagnostico, investigación y monitoreo</h2>
                        <p>Estudio de la zona donde se va a implementar la Telesalud.</p>
                        <Link to = "servicios#monitoreo">Explora más</Link>
                    </div>
                </div>
                <div className = "columns">
                    <div className = "block">
                        <Capacitacion></Capacitacion>
                        <h2>Capacitación</h2>
                        <p>Brindamos capacitación sobre el uso de la plataforma, equipo biomédico
                            e informático
                        </p>
                        <Link to = "servicios#monitoreo">Explora más</Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default Servicios