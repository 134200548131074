import React from "react"
import { graphql, useStaticQuery} from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage} from "gbimage-bridge"
import CountUp from 'react-countup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag, faMedkit, faProcedures, faLaptopCode, faHospital, faChalkboardTeacher} from '@fortawesome/free-solid-svg-icons'
import ButtonAprende from "../Button"
import { Link } from "gatsby";

const Resultados = () => {
    const {backgroundImage123} = useStaticQuery (
        graphql`
        query {
            backgroundImage123: file(relativePath: {eq: "resultadosBG.png"}) {
                childImageSharp {
                  gatsbyImageData(
                    width: 2000, 
                    quality: 50, 
                    webpOptions: {quality: 70})
                }
            }
        }
    `
    )

    const pluginImage = getImage(backgroundImage123)
    return (
       <BgImage image ={pluginImage}  id= "resultados">
           <h1>Resultados que importan</h1>
           <div className = "flex-grid-thirds">
               <div className = "col">
                   <div className = "block">
                        <FontAwesomeIcon icon={faFlag} />
                         <h2><CountUp end={6} delay={4} /></h2>
                        <p>Departamentos en 18 meses.</p>
                   </div>
                </div>
                <div className = "col">
                    <div className = "block">
                        <FontAwesomeIcon icon={faMedkit} />
                        <h2><CountUp end={27} delay={4}/></h2>
                        <p>Establecimientos de salud con equipo de primer nivel.</p>
                    </div>
                </div>
                <div className = "col">
                    <div className = "block">
                        <FontAwesomeIcon icon={faProcedures} />
                        <h2><CountUp end={9}  delay={4}/></h2>
                        <p>Hospitales con equipo para TeleSalud + FCM UN </p>
                    </div>
                </div>
            </div>
            <div className = "flex-grid-thirds">
                <div className = "col">
                    <div className = "block">
                        <FontAwesomeIcon icon={faLaptopCode} />
                       <h2> +<CountUp end={500}  delay={4} />  </h2> 
                        <p>Personas alcanzadas con el Programa de Actualización en Salud de 
                            Teleeducación.
                        </p>
                    </div>
                </div>
                <div className = "col">
                    <div className = "block">
                        <FontAwesomeIcon icon={faChalkboardTeacher} />
                        <h2>+<CountUp end={300}  delay={4} /></h2>
                        <p>Teleconsultas en menos de 4 meses. </p>
                    </div>
                </div>
                <div className = "col">
                    <div className = "block">
                        <FontAwesomeIcon icon={faHospital} />
                        <h2>+<CountUp end={44}  delay={4}/></h2>
                        <p>37 Unidades Remotas de Telesalud <br/> y 7 Centros
                            de Operaciones de Telesalud </p>
                    </div>
                </div>
           </div>
           <Link to="resultados">
                <ButtonAprende label="CONOCÉNOS"/>
            </Link>
       </BgImage>
    )

}

export default Resultados