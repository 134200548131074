import React from 'react';
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

/**Internacionales */
import asociIberoamericana from '../../assets/alianzas/internacionales/aItt.webp'
import cenetec from '../../assets/alianzas/internacionales/cenetec_salud.webp'
import goal from '../../assets/alianzas/internacionales/GOAL.webp'
import logoUnionEuropea  from '../../assets/alianzas/internacionales/logo_union_europea_es.webp'
import paliativos  from '../../assets/alianzas/internacionales/paliativos.webp'
import redInformaticaSalud  from '../../assets/alianzas/internacionales/red_informatica_salud.webp'
import seguroSocialCostaRica  from '../../assets/alianzas/internacionales/seguro_social_costa_rica.webp'
import sihilogo  from '../../assets/alianzas/internacionales/sihi_logo.webp'
import socialSkin  from '../../assets/alianzas/internacionales/social-skin.webp'
import padf from "../../assets/alianzas/internacionales/PADF-English_Blue.png"


/**Nacionales */
import arsa  from '../../assets/alianzas/nacionales/arsa.webp'
import conatel  from '../../assets/alianzas/nacionales/conatel.webp'
import eurosan  from '../../assets/alianzas/nacionales/eurosan.webp'
import facultad_medicina from  '../../assets/alianzas/nacionales/facultad_medicina.webp'
import FundeSur from  '../../assets/alianzas/nacionales/FundeSur.webp'
import globalBrigades from  '../../assets/alianzas/nacionales/global_brigades.webp'
import hospitalEscuela from  '../../assets/alianzas/nacionales/hospital_escuela.webp'
import hospitalSanFelipe from  '../../assets/alianzas/nacionales/hospital_san_felipe.webp'
import hospitalMaria from  '../../assets/alianzas/nacionales/hospitalMaria.webp'
import iaip from  '../../assets/alianzas/nacionales/iaip.webp'
import MASTA from  '../../assets/alianzas/nacionales/masta.webp'
import MOPAWI from  '../../assets/alianzas/nacionales/mopawi.webp'
import OPS from  '../../assets/alianzas/nacionales/ops.webp'
import SCGG from  '../../assets/alianzas/nacionales/scgg.webp'
import secretarDefensaNacional from  '../../assets/alianzas/nacionales/secretaria_defensa_nacional.webp'
import UNAH from  '../../assets/alianzas/nacionales/unah.webp'


const SocialProof = props => {
  const handleOnDragStart = (e) => e.preventDefault()
  
 
  return (
    <section id = "alianza"> 
        <h1>Alianzas</h1>        
        <div id = "nacionales" className="mobile-alianzas">
        <h2>Internacionales</h2>
        <AliceCarousel 
            mouseTracking = {true}
            buttonsDisabled = {true}
            infinite = {true}
            autoPlay = {true}
            autoPlayInterval={2000}
            responsive={
              { 
                "0": { items: 4 }
              }}
            disableButtonsControls = {true}
        >
            <img src= {asociIberoamericana} onDragStart={handleOnDragStart} width = "200" alt ="Asociación Iberoamériana de Telesalud y Telemedicina" />
            <img src= {cenetec} onDragStart={handleOnDragStart} width = "200" alt = "Cenetec-salud" />
            <img src={logoUnionEuropea} onDragStart={handleOnDragStart}  width = "200" alt = "Unión Europea"/>
            <img src={goal} onDragStart={handleOnDragStart} width = "200" alt = "goal" />
            <img src={paliativos} onDragStart={handleOnDragStart} width = "200" alt = "Paliativos sin fronteras"/>
            <img src= {redInformaticaSalud} onDragStart={handleOnDragStart} width = "200" alt = "Red centroaméricana de informática en salud" />
            <img src= {seguroSocialCostaRica} onDragStart={handleOnDragStart}  width = "200" alt = "Seguro social de Costa Rica"/>
            <img src= {sihilogo} onDragStart={handleOnDragStart} width = "200" alt= "social innovation in health initiative" />
            <img src= {socialSkin} onDragStart={handleOnDragStart} width = "200" alt = "social skin"/>
            <img src = {padf} onDragStart={handleOnDragStart} width = "200" alt = "padf" />
      </AliceCarousel>
      <h2>Nacionales</h2>
      <AliceCarousel 
              mouseTracking = {true}
              buttonsDisabled = {true}
              infinite = {true}
              autoPlay = {true}
              autoPlayInterval={2000}
              responsive={
                { 
                  "0": { items: 4 }
                 
                }}
              disableButtonsControls = {true}
        >
            <img src= {arsa} onDragStart={handleOnDragStart} width = "200" alt = "agencia de regulación sanitaria" />
            <img src= {conatel} onDragStart={handleOnDragStart} width = "200" alt = "conatel" />
            <img src={eurosan} onDragStart={handleOnDragStart} width = "200" alt = "eurosan"/>
            <img src={facultad_medicina} onDragStart={handleOnDragStart} width = "200" alt = "facultad de medicina" />
            <img src={FundeSur} onDragStart={handleOnDragStart} width = "200" alt ="FundeSur" />
            <img src= {globalBrigades} onDragStart={handleOnDragStart} width = "200" alt="Global brigades" />
            <img src= {hospitalEscuela} onDragStart={handleOnDragStart} alt="hospital escuela" />
            <img src= {hospitalSanFelipe} onDragStart={handleOnDragStart} alt ="hospital san felipe" />
            <img src= {hospitalMaria} onDragStart={handleOnDragStart} width = "200" alt = "hospital María" />
            <img src= {iaip} onDragStart={handleOnDragStart} width = "200" alt = "instituto de acceso a la información pública" />
            <img src= {MASTA} onDragStart={handleOnDragStart} width = "200" alt = "Unidade de la Moskitia" />
            <img src= {MOPAWI} onDragStart={handleOnDragStart} width = "200" alt = "Mopawi" />
            <img src= {OPS} onDragStart={handleOnDragStart} width = "200" alt = "Organización panaméricana de la salud" />
            <img src= {SCGG} onDragStart={handleOnDragStart} width = "200" alt = "Unidad técnica de seguridad alimentaria y nutricional " />
            <img src= {secretarDefensaNacional} onDragStart={handleOnDragStart} alt ="secretaria de defensa nacional" />
            <img src= {UNAH} onDragStart={handleOnDragStart} width = "200" alt = "Universidad Autonóma Nacional de Honduras" />
    </AliceCarousel>
        </div>
    </section>
     
  )
};

export default SocialProof;
