import React from "react";

function Youtube() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      width = "64"
      height = "64"
    >
      <path
        fill="#E35336"
        d="M435.574 444.125H76.426c-37.781 0-68.409-30.628-68.409-68.409V136.284c0-37.781 30.628-68.409 68.409-68.409h359.148c37.781 0 68.409 30.628 68.409 68.409v239.432c0 37.781-30.628 68.409-68.409 68.409z"
      ></path>
      <path
        fill="#D93C1C"
        d="M469.779 409.921H93.528c-28.336 0-51.307-22.971-51.307-51.307V102.079a68.116 68.116 0 016.061-28.144C24.544 84.668 8.017 108.539 8.017 136.284v239.432c0 37.781 30.628 68.409 68.409 68.409h359.148c27.745 0 51.616-16.527 62.349-40.265a68.136 68.136 0 01-28.144 6.061z"
      ></path>
      <path
        fill="#FFF"
        d="M358.614 256L187.591 367.165 187.591 144.835z"
      ></path>
      <path
        fill="#E5E5E5"
        d="M213.244 161.51L187.591 144.835 187.591 367.165 213.244 350.49z"
      ></path>
      <path d="M435.574 59.858H76.426C34.285 59.858 0 94.143 0 136.284v171.023a8.017 8.017 0 0016.034 0V136.284c0-33.3 27.092-60.392 60.392-60.392h359.148c33.3 0 60.392 27.092 60.392 60.392v239.432c0 33.3-27.092 60.392-60.392 60.392H76.426c-33.3 0-60.392-27.092-60.392-60.392v-34.205a8.017 8.017 0 00-16.034 0v34.205c0 42.141 34.285 76.426 76.426 76.426h359.148c42.141 0 76.426-34.285 76.426-76.426V136.284c0-42.141-34.285-76.426-76.426-76.426z"></path>
      <path d="M294.386 223.813a8.018 8.018 0 0011.09-2.353 8.016 8.016 0 00-2.353-11.091l-111.165-72.256c-5.24-3.407-12.384.491-12.384 6.721v222.33c0 6.226 7.142 10.131 12.385 6.721L362.982 262.72c4.779-3.106 4.778-10.336 0-13.443l-34.205-22.233a8.018 8.018 0 00-8.738 13.444l23.864 15.511-148.296 96.394V159.607l98.779 64.206z"></path>
    </svg>
  );
}

export default Youtube;
