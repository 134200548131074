import React from "react"
import { graphql, useStaticQuery} from "gatsby"
import { getImage, StaticImage  } from "gatsby-plugin-image"
import { BgImage} from "gbimage-bridge"
import Button from "../Button"
import { Link } from "gatsby";
const Cta = () => {
    const {backgroundImage123} = useStaticQuery (
        graphql`
        query {
            backgroundImage123: file(relativePath: {eq: "cta.jpg"}) {
                childImageSharp {
                  gatsbyImageData(
                    width: 2000, 
                    quality: 50, 
                    webpOptions: {quality: 70})
                }
            }
        }
    `
    )

    const pluginImage = getImage(backgroundImage123)
    return (
       <BgImage image ={pluginImage} className="masthead">
         <div id="CtaTelesan">
             <div className= "content">
                <div className = "row">
                    <div className = "column">
                        <StaticImage
                            src = "../../assets/telesanCta_fronteras.jpg"
                            width = {450}
                            alt= "Telesan plataforma"
                        />  
                    </div>
                    <div className = "column" id= "texto">
                        <div className= "block">
                            <h1>¿Querés conectar fronteras con nosotros?</h1>
                            <h2>Somos un equipo de voluntarios multidiciplinario que busca mejorar el acceso
                            a la salud.
                            </h2>
                            <Link to="contacto#info-contacto">
                            <Button label = "Sí, quiero ser parte"/>
                            </Link>
                         </div>
                 </div>
                
                </div>
            </div>
         </div>
         <div>
 
         </div>
       </BgImage>
    )

}

export default Cta