import React from "react"
import BackgroundSlider from 'react-background-slider'
import inicio1 from "../../assets/telesan-inicio/telesan_inicio1.jpg";
import inicio2 from "../../assets/telesan-inicio/telesan_inicio2.jpg";
import inicio3 from "../../assets/telesan-inicio/telesan_inicio3.jpg";
import inicio5 from "../../assets/telesan-inicio/telesan_inicio5.jpg";
import inicio6 from "../../assets/telesan-inicio/telesan_inicio6.jpg";
import { Link } from "gatsby";
import Button from "../Button"

const HeroMain = () => {
 
    return (
         <div className = "masthead">   
            <BackgroundSlider
                images={[inicio1, inicio2, inicio3, inicio5, inicio6]}
                duration={5}
                transition={2}
                />
            <div className = "heroboxtext">
                <h1>Derribando Barreras Conectando Fronteras</h1>
                <p>Conectamos los servicios de las comunidades remotas con los servicios 
                    de especialidades médicas en Honduras</p>
                <Link to="telesalud">
                 <Button label="CONOCÉNOS"/>
                 </Link>    

            </div>
         </div>
    )

}

export default HeroMain